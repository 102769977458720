<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    default: '',
  },

  icon: {
    type: String,
    default: '',
  },

  notFound: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div
    class="flex items-center bg-white border  w-fit py-1 px-1 rounded-md cursor-pointer"
    :class="notFound ? 'border-error-100' : 'border-neutral-100'"
  >
    <span
      class="text-xs"
      :class="notFound ? 'text-error-100' : 'text-neutral-200 '"
    >
      {{ props.text }}
    </span>
    <Icon
      v-if="props.icon"
      :name="props.icon"
      class="ml-1"
      :class="notFound ? 'text-error-100' : 'text-neutral-200'"
      width="12"
      height="12"
    />
  </div>
</template>
