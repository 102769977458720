<script setup lang="ts">
import type { OrdenationOption } from '@/types/types'
import { EventName, SearchProcedureOrdenation, SearchProcedureOrdenationMapping, SessionStorageKey } from '@/types/enums'

const options = Object.values(SearchProcedureOrdenationMapping)

const showOptions = ref(false)

function changeOptionOrdenation(option: OrdenationOption): void {
  track(EventName.SelectOrdenation, {
    ordenationOption: option.value,
  })

  if (option.value === SearchProcedureOrdenation.DISTANCE && useLocation().value?.lat) {
    useSearchProcedureOrdenation().value = option

    showOptions.value = false

    return
  }

  if (option.value === SearchProcedureOrdenation.PRICE || option.value === SearchProcedureOrdenation.RELEVANCE) {
    useSessionStorage(SessionStorageKey.GeolocationPopup, false).value = false

    useSearchProcedureOrdenation().value = option

    showOptions.value = false

    return
  }

  showOptions.value = false
  useSessionStorage(SessionStorageKey.GeolocationPopup, false).value = true
}
</script>

<template>
  <section
    class="rounded-lg py-4 border border-neutral-50 hover:border-primary-500 text-primary-500 cursor-pointer duration-300 w-full xl:w-[250px] relative"
    :class="{
      'border-primary-400 cursor-default hover:border-primary-50': showOptions,
      'h-[56px]': !showOptions,
    }"
  >
    <span class="absolute -top-[14px] left-2 p-1 text-primary-500 text-xs bg-base-50">
      Ordenar por
    </span>

    <div class="flex items-center justify-between px-4" @click="showOptions = !showOptions">
      <span class="flex items-center gap-2">
        <Icon name="mdi:sort-ascending" class="w-6 h-6" />
        <span>{{ useSearchProcedureOrdenation().value.name }}</span>
      </span>

      <span>
        <Icon
          name="mdi:chevron-down"
          class="w-6 h-6 duration-300"
          :class="[{ ['rotate-180']: showOptions }]"
        />
      </span>
    </div>

    <ul v-if="showOptions" class="mt-4">
      <li
        v-for="option in options"
        v-show="useSearchProcedureOrdenation().value.value !== option.value"
        :key="option.value"
        class="w-full"
        @click="changeOptionOrdenation(option)"
      >
        <span class="flex items-center py-2 px-4 gap-4 cursor-pointer hover:bg-neutral-200 hover:bg-opacity-10">
          {{ option.name }}
        </span>
      </li>
    </ul>
  </section>
</template>
