<script setup lang="ts">
import type { ISearchProcedureResponseItem } from '@/types/types'
import { EventName } from '@/types/enums'

const props = defineProps({
  id: {
    type: String,
    default: 'search-procedure-id',
  },
  initialSelected: {
    type: Array,
    default: () => [],
  },
  buttonTitle: {
    type: String,
    default: 'Buscar',
  },
  withoutOrdenation: {
    type: Boolean,
    default: false,
  },
  noButton: {
    type: Boolean,
    default: false,
  },
  noBadge: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['searchProcedures', 'selectProcedure', 'removeProcedure'])

const term = ref('')
const dTerm = refDebounced(term, 300, { maxWait: 600 })
const selected = ref<ISearchProcedureResponseItem[]>([])
const searchInput = ref()

const { procedures, pending: loadingProcedure } = useProcedures()

const animation = reactive({
  procedures: ['Hemograma', 'Espermograma', 'Papanicolau', 'Creatinina', 'Ultrassom Morfológico', 'Ureia'],
  index: 0,
})

const { pending, data: searched } = useSearchProcedure(dTerm, props.id, selected)

const placeholder = computed(() => selected.value.length ? 'Você tem mais exames? Digite aqui' : 'Buscar')

function selectProcedure({ name, slug, id, procedureId }: ISearchProcedureResponseItem) {
  track(EventName.ExamSelect, {
    procedureDisplayId: id,
    displayName: name,
  })

  selected.value = [...selected.value, { name, slug, id, procedureId }]

  term.value = ''

  emit('selectProcedure', slug)
}

function removeProcedure(procedure: ISearchProcedureResponseItem) {
  selected.value = selected.value.filter((selected: any) => selected.slug !== procedure.slug)

  emit('removeProcedure', procedure)
}

function notSelectedProcedure(id: string): boolean {
  const isInSelectedProcedures = procedures.value.some(
    procedure => procedure.procedure.procedureId === id,
  )
  const isInSelectedBadges = selected.value.some(
    procedureBadge => procedureBadge.procedureId === id,
  )

  return !(isInSelectedProcedures || isInSelectedBadges)
}

function searchProcedures() {
  const slugSelected = selected.value.map(({ slug }: any) => slug)

  selected.value = []

  track(EventName.Search, {
    cityId: useLocation().value?.id,
    citySlug: useLocation().value?.slug,
    procedureCount: slugSelected.length,
  })

  emit('searchProcedures', slugSelected)
}

function procedureNameShow(name: string): string {
  return name.length > 45 ? `${name.slice(0, 45)}...` : name
}

function handleInput(event: any) {
  if (event?.target?.composing)
    event.target.composing = false
}

const content = useContent()

useIntervalFn(() => {
  if ((animation.index + 1) >= animation.procedures.length) {
    animation.index = 0

    return
  }

  animation.index++
}, 2000)

onNuxtReady(() => {
  if (props.initialSelected)
    selected.value = props.initialSelected

  searchInput.value?.focus()
})
</script>

<template>
  <div
    class="flex gap-3"
    :class="{
      'flex-nowrap flex-row': withoutOrdenation,
      'flex-wrap flex-col xl:flex-row': !withoutOrdenation,
      'flex-col xl:flex-row': compact,
    }"
  >
    <div
      class="bg-white shadow-md relative transition-all xl:min-w-[465px] w-full xl:flex-1 h-full"
      :class="searched.length ? 'rounded-t-lg' : 'rounded-lg'"
    >
      <div class="flex items-center justify-between py-4 group px-3">
        <label for="search" class="sr-only">Input para a busca de exames</label>

        <Icon
          :name="pending || loadingProcedure ? 'mdi:loading' : 'mdi:magnify'"
          class="text-primary-500"
          :class="{ 'animate-spin': pending || loadingProcedure }"
          height="24"
          width="24"
        />

        <transition
          v-if="!selected.length && !term.length"
          name="slide-fade"
          enter-active-class="animate__animated animate__flipInX animate__faster"
          leave-active-class="animate__animated animate__flipOutX animate__faster"
          mode="out-in"
        >
          <div
            :key="animation.procedures[animation.index]"
            class="absolute xl:ml-[88px] md:ml-[72px] ml-[70px] text-xs xl:text-base text-primary-500"
            @click="() => searchInput?.focus()"
          >
            {{ animation.procedures[animation.index] }}
          </div>
        </transition>

        <input
          id="search"
          ref="searchInput"
          v-model.trim="term"
          data-testid="search-procedure-input"
          autocomplete="off"
          type="text"
          class="w-full mx-4 text-xs xl:text-base placeholder:text-primary-500 focus-visible:outline-0 text-neutral-500"
          :placeholder="placeholder"
          autofocus
          @input="handleInput"
        />

        <button @click="term = ''">
          <Icon
            v-if="term.length"
            name="mdi:close"
            class="text-primary-500 cursor-pointer"
            height="24"
            width="24"
          />
        </button>
      </div>

      <div
        v-if="selected.length && !noBadge"
        class="flex flex-wrap px-3 z-50 pb-2 bg-white rounded-b-lg"
      >
        <BadgeSearchProcedure
          v-for="(procedure, index) in selected"
          :key="`badge-${index}`"
          class="mr-1 mt-2 animate__animated animate__fadeInDown word-break-all width-100px"
          icon="mdi:close"
          :text="procedureNameShow(procedure.name)"
          @click.stop
          @click="removeProcedure(procedure)"
        />
      </div>

      <hr v-if="searched.length" class="mx-4" />

      <div
        v-if="searched.length"
        class="absolute z-10 w-full shadow rounded-b-lg"
      >
        <ul
          class="bg-white animate__animated animate__fadeIn top-full max-h-56 overflow-y-auto"
        >
          <li
            v-for="item in searched"
            :key="item.id"
            class="animate__fadeInDown animate__animated animate__faster"
            data-testid="search-procedure-list-item"
            @click="selectProcedure(item)"
          >
            <span
              v-if="notSelectedProcedure(item.procedureId)"
              class=" flex items-center py-2 px-5 hover:bg-primary-50 cursor-pointer"
            >
              <input
                :id="item.id"
                type="checkbox"
                name="search-exam"
                class="appearance-none bg-white border-2 border-neutral-300 cursor-pointer rounded-sm w-5 h-5 relative peer shrink-0 checked:bg-primary-400 checked:text-white checked:border-0 checked:before:content-['\2713'] flex justify-center items-center text-base font-semibold"
                @focusout.stop
              />
              <label
                class="text-neutral-500 text-sm ml-5 cursor-pointer"
                :for="item.id"
                @click.stop
              >
                {{ item.name }}
              </label>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <Button
      v-if="!noButton"
      :disabled="!selected.length"
      :class="{
        'max-w-[95px] xl:max-w-[167px]': withoutOrdenation,
        'xl:max-w-[167px]': !withoutOrdenation,
        'h-12 max-w-[unset]': compact,
        'h-[56px]': !compact,
      }"
      class="w-full"
      @click="searchProcedures"
    >
      <span>
        {{ buttonTitle }}
      </span>
    </Button>

    <SearchProcedureOrdenation v-if="!withoutOrdenation && content.multiLocation" />
  </div>
</template>
